import React from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { CiAlarmOn } from 'react-icons/ci';
import { FaBoxOpen, FaFileAlt } from 'react-icons/fa';
import { GiMailbox } from 'react-icons/gi';
import { ImDrawer, ImUserCheck } from 'react-icons/im';

// import { RiSearchEyeLine } from 'react-icons/ri';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DevicesIcon from '@material-ui/icons/Devices';
import DoneAllIcon from '@material-ui/icons/Done';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import CleaningServicesSharpIcon from '@mui/icons-material/CleaningServicesSharp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ScienceIcon from '@mui/icons-material/Science';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SpeedIcon from '@mui/icons-material/Speed';
import StarIcon from '@mui/icons-material/Star';
import SummarizeIcon from '@mui/icons-material/Summarize';

export const menu = [
  {
    id: 'home',
    path: '/home',
    label: 'Ínicio',
    icon: <HomeIcon />,
    roles: 1,
    items: [
      {
        path: '/home',
        label: 'Página inicial',
        icon: <HomeIcon />,
        roles: 1,
      },
    ],
  },
  {
    id: 'favoritos',
    path: '/home',
    label: 'Favoritos',
    icon: <StarIcon />,
    roles: 1,
    items: [],
  },
  {
    id: 'general-registration',
    label: 'Cadastro Gerais',
    icon: <BsPencilSquare size='18' />,
    roles: 2,
    items: [],
  },
  {
    id: 'cashier',
    label: 'Caixa',
    icon: <AttachMoneyIcon />,
    roles: 3,
    items: [],
  },
  {
    id: 'stock',
    label: 'Estoque',
    icon: <FaBoxOpen size='18' />,
    roles: 4,
    items: [
      {
        path: '/inventory-lock',
        label: 'Trava de inventário',
        icon: <SupervisedUserCircleIcon size='18' />,
        roles: 700,
      },
    ],
  },
  {
    id: 'expedition',
    label: 'Expedição',
    icon: <LocalShippingIcon />,
    roles: 5,
    items: [],
  },
  {
    id: 'conference',
    label: 'Conferência',
    icon: <PlaylistAddCheckIcon />,
    roles: 19,
    items: [
      {
        path: '/conference-beeping',
        label: 'Conferencia por Bipagem',
        icon: <DoneAllIcon />,
        roles: 300,
      },
      {
        path: '/nf3vsnf4',
        label: 'NF3 vs NF4',
        icon: <DoneAllIcon />,
        roles: 301,
      },
    ],
  },
  {
    id: 'screening',
    label: 'Triagem',
    icon: <SpeedIcon />,
    roles: 21,
    items: [],
    items: [
      {
        path: '/screening-clear',
        label: 'Limpeza',
        icon: <CleaningServicesSharpIcon />,
        roles: 601,
      },
      {
        path: '/screening-data-recovery',
        label: 'Recuperação de Dados',
        icon: <SettingsSuggestIcon />,
        roles: 602,
      },
      {
        path: '/cancellation-laudo',
        label: 'Cancelamento de Laudo Triagem',
        roles: 603,
      },
      {
        path: '/cosmetic-screening',
        label: 'Triagem Cosmética',
        roles: 604,
      },
    ],
  },
  {
    id: 'warranty',
    label: 'Garantia',
    icon: <VerifiedUserIcon />,
    roles: 6,
    items: [],
  },
  {
    id: 'implementation',
    label: 'Implementação',
    icon: <ImDrawer />,
    roles: 7,
    items: [],
  },
  {
    id: 'general-releases',
    label: 'Liberações de liderança',
    icon: <SupervisedUserCircleIcon size='18' />,
    roles: 8,
    items: [],
  },
  {
    id: 'service-order',
    label: 'Ordem de Serviço',
    icon: <FaFileAlt />,
    roles: 9,
    items: [
      {
        path: '/schedule-open-service-order',
        label: 'Agendar abertura de OS',
        icon: <CiAlarmOn size='18' />,
        roles: 140,
      },
    ],
  },
  {
    id: 'pcp',
    label: 'PCP',
    icon: <ImUserCheck size='18' />,
    roles: 10,
    items: [
      {
        path: '/os-assignment',
        label: 'Atribuição de Os',
        icon: <PersonAddIcon />,
        roles: 500,
      },
    ],
  },
  {
    id: 'laboratory',
    label: 'Laboratório',
    icon: <ScienceIcon />,
    roles: 22,
    items: [
      {
        path: '/lots-of-customization',
        label: 'Lotes de Personalização',
        icon: <ScienceIcon />,
        roles: 800,
      },
      {
        path: '/downtime',
        label: 'Downtime',
        icon: <ScienceIcon />,
        roles: 801,
      },
    ],
  },
  {
    id: 'production',
    label: 'Produção',
    icon: <DevicesIcon />,
    roles: 11,
    items: [],
  },
  {
    id: 'receivement',
    label: 'Recebimento',
    icon: <GiMailbox />,
    roles: 12,
    items: [
      {
        path: '/correction-nf',
        label: 'Correção NF',
        icon: <GiMailbox />,
        roles: 901,
      },
      {
        path: '/archives-download',
        label: 'Download de Arquivos',
        icon: <GiMailbox />,
        roles: 900,
      },
      {
        path: '/pre-receivement-import',
        label: 'Pré-Recebimento Import',
        icon: <GiMailbox />,
        roles: 902,
      },
    ],
  },
  {
    id: 'launch',
    label: 'Lançamento',
    icon: <GiMailbox />,
    roles: 20,
    items: [
      {
        path: '/compact-report',
        label: 'Compactar Laudo',
        icon: <PersonAddIcon />,
        roles: 400,
      },
      {
        path: '/freezing-system',
        label: 'Sistema de Congelamento SLAs',
        icon: <PersonAddIcon />,
        roles: 401,
      },
      {
        path: '/cancellation-laudo',
        label: 'Cancelar laudo',
        icon: <PersonAddIcon />,
        roles: 402,
      },
      {
        path: '/cosmetic-screening',
        label: 'Triagem cosmetica',
        icon: <PersonAddIcon />,
        roles: 403,
      },
      {
        path: '/limit-registre',
        label: 'Cadastro TETO',
        icon: <PersonAddIcon />,
        roles: 404,
      },
      {
        path: '/os-block',
        label: 'Bloqueio de OS',
        icon: <PersonAddIcon />,
        roles: 406,
      },
    ],
  },
  {
    id: 'access-control',
    label: 'Controle de Acesso',
    icon: <PersonAddIcon />,
    roles: 14,
    items: [
      {
        path: '/create-role',
        label: 'Novo Grupo de Usuário',
        icon: <PersonAddIcon />,
        roles: 151,
      },
      {
        path: '/permissions',
        label: 'Permissionamento',
        icon: <PersonAddIcon />,
        roles: 152,
      },
      {
        path: '/list-profiles',
        label: 'Perfis',
        icon: <PersonAddIcon />,
        roles: 162,
      },
    ],
  },
  // NÃO EXCLUIR
  {
    id: 'reports-general',
    label: 'Relatórios',
    icon: <SummarizeIcon />,
    roles: 16,
    items: [
      {
        path: '/report-freezing',
        label: 'Relatório de Congelamento',
        icon: <DoneAllIcon />,
        roles: 182,
      },
      {
        path: '/report-invoicesReport',
        label: 'Relatório de Faturamento',
        icon: <DoneAllIcon />,
        roles: 192,
      },
      {
        path: '/serial-partnumber',
        label: 'Relatório Serial para Parnumber',
        icon: <DoneAllIcon />,
        roles: 201,
      },
      {
        path: '/aging-report',
        label: 'Aging',
        icon: <DoneAllIcon />,
        roles: 208,
      },
      {
        path: '/in-out',
        label: 'Relatório IN OUT',
        icon: <DoneAllIcon />,
        roles: 209,
      },
      {
        path: '/biping-conference-report',
        label: 'Relatório de Conferência por bipagem',
        icon: <DoneAllIcon />,
        roles: 210,
      },
      {
        path: '/aging-with-parts',
        label: 'Aging Com Peças',
        icon: <DoneAllIcon />,
        roles: 212,
      },
      {
        path: '/parts-consumption',
        label: 'Consumo de Peças',
        icon: <DoneAllIcon />,
        roles: 218,
      },
      {
        path: '/remove-black-list',
        label: 'Remover blacklist',
        icon: <DoneAllIcon />,
        roles: 223,
      },
      {
        path: '/serial-base-query',
        label: 'Relatório Consulta Base de Seriais',
        icon: <DoneAllIcon />,
        roles: 226,
      },
      {
        path: '/closing-by-nf1',
        label: 'Relatório Fechamento por NF1',
        icon: <DoneAllIcon />,
        roles: 228,
      },
      {
        path: '/production-cleaning',
        label: 'Relatório Produção Limpeza',
        icon: <DoneAllIcon />,
        roles: 236,
      },
      {
        path: '/value-technical-stock',
        label: 'Relatório Valor Estoque Técnico',
        icon: <DoneAllIcon />,
        roles: 237,
      },
      {
        path: '/status-os',
        label: 'Relatório Status OS',
        icon: <DoneAllIcon />,
        roles: 249,
      },
      {
        path: '/incontrol-report',
        label: 'Relatório Logs Catracas',
        icon: <DoneAllIcon />,
        roles: 253,
      },
    ],
  },
  {
    id: 'reports-conference',
    label: 'Relatórios Conferência',
    icon: <SummarizeIcon />,
    roles: 22,
    items: [
      {
        path: '/open-os-report',
        label: 'Relatório de Abertura de OS',
        icon: <DoneAllIcon />,
        roles: 204,
      },
      {
        path: '/aging-freezing-report',
        label: 'Aging Congelamento',
        icon: <DoneAllIcon />,
        roles: 211,
      },
      {
        path: '/biping-conference-report',
        label: 'Relatório de Conferência por bipagem',
        icon: <DoneAllIcon />,
        roles: 210,
      },
      {
        path: '/conference-production',
        label: 'Produção da Conferência',
        icon: <DoneAllIcon />,
        roles: 219,
      },
    ],
  },
  {
    id: 'reports-engineering',
    label: 'Relatórios Engenharia',
    icon: <SummarizeIcon />,
    roles: 23,
    items: [
      {
        path: '/oS-Part-Release-Control-Report',
        label: 'Relatório Controle Liberação Peça OS',
        icon: <DoneAllIcon />,
        roles: 220,
      },
      {
        path: '/doaBounce-Report',
        label: 'Relatório doaBounce',
        icon: <DoneAllIcon />,
        roles: 221,
      },
      {
        path: '/report-freezing-pendin',
        label: 'Relatório Congelamento Pendência Ingenico',
        icon: <DoneAllIcon />,
        roles: 222,
      },
      {
        path: '/blacklist-query',
        label: 'Consulta de blacklist',
        icon: <DoneAllIcon />,
        roles: 227,
      },
      {
        path: '/validate-info-recuse',
        label: 'Valida Info Recusa/Rejeição',
        icon: <DoneAllIcon />,
        roles: 250,
      },
      {
        path: '/where-used',
        label: 'Where Used',
        icon: <DoneAllIcon />,
        roles: 251,
      },
    ],
  },
  {
    id: 'reports-stock',
    label: 'Relatórios Estoque',
    icon: <SummarizeIcon />,
    roles: 24,
    items: [
      {
        path: '/component-bor',
        label: 'BOR Por Componente',
        icon: <DoneAllIcon />,
        roles: 215,
      },
      {
        path: '/parts-x-model-bor-report',
        label: 'Peças X Modelos (BOR)',
        icon: <DoneAllIcon />,
        roles: 213,
      },
      {
        path: '/technical-stock',
        label: 'Relatório de Estoque técnico',
        icon: <DoneAllIcon />,
        roles: 203,
      },
      {
        path: '/car-stock-report',
        label: 'Relatório de Estoque CAR',
        icon: <DoneAllIcon />,
        roles: 205,
      },
      {
        path: '/stock-report',
        label: 'Relatório de Estoque',
        icon: <DoneAllIcon />,
        roles: 206,
      },
      {
        path: '/log-Mov-By-Location-Report',
        label: 'Log Movimentação (por Local)',
        icon: <DoneAllIcon />,
        roles: 207,
      },
      {
        path: '/manual-movimentation',
        label: 'Relatório Movimentação Manual',
        icon: <DoneAllIcon />,
        roles: 217,
      },
      {
        path: '/bor-by-pn',
        label: 'Relatório BOR por PN',
        icon: <DoneAllIcon />,
        roles: 242,
      },
      {
        path: '/bor-without-pn',
        label: 'Relatório BOR sem PN',
        icon: <DoneAllIcon />,
        roles: 243,
      },
      {
        path: '/minimum-stock',
        label: 'Relatório Estoque Mínimo',
        icon: <DoneAllIcon />,
        roles: 244,
      },
      {
        path: '/requests-report',
        label: 'Relatório Requisições',
        icon: <DoneAllIcon />,
        roles: 245,
      },
      {
        path: '/wfp-with-pn',
        label: 'Relatório WFP com PN',
        icon: <DoneAllIcon />,
        roles: 246,
      },
      {
        path: '/cancellation-chargebacks',
        label: 'Relatório Cancelamento de Estornos',
        icon: <DoneAllIcon />,
        roles: 225,
      },
    ],
  },
  {
    id: 'reports-lab',
    label: 'Relatórios Laboratório',
    icon: <SummarizeIcon />,
    roles: 25,
    items: [
      {
        path: '/report-production-department',
        label: 'Relatório Produção por Setor',
        icon: <DoneAllIcon />,
        roles: 199,
      },
      {
        path: '/autoCheck-cq',
        label: 'Relatório AutoCheck CQ',
        icon: <DoneAllIcon />,
        roles: 238,
      },
      {
        path: '/quantity-laudo-day',
        label: 'Relatório Quantidade de Laudos por Dia',
        icon: <DoneAllIcon />,
        roles: 235,
      },
      {
        path: '/express-repair',
        label: 'Relatório Reparo Expresso',
        icon: <DoneAllIcon />,
        roles: 234,
      },
      {
        path: '/cosmetic-screening-report',
        label: 'Relatório Triagem Cosmética',
        icon: <DoneAllIcon />,
        roles: 233,
      },
      {
        path: '/view-laudos',
        label: 'Visualização de Laudos',
        icon: <DoneAllIcon />,
        roles: 231,
      },
      {
        path: '/launch-parts',
        label: 'Relatório Lançamento de Peças',
        icon: <DoneAllIcon />,
        roles: 229,
      },
    ],
  },
  {
    id: 'reports-shipping',
    label: 'Relatórios Expedição',
    icon: <SummarizeIcon />,
    roles: 26,
    items: [
      {
        path: '/inventary-expedition',
        label: 'Inventário Expedição',
        icon: <DoneAllIcon />,
        roles: 214,
      },
      {
        path: '/report-os-expedition',
        label: 'Relatório OSs Expedidas',
        icon: <DoneAllIcon />,
        roles: 200,
      },
      {
        path: '/devol-report',
        label: 'Relatório Devol',
        icon: <DoneAllIcon />,
        roles: 241,
      },
      {
        path: '/packaging-report',
        label: 'Relatório Embalagem',
        icon: <DoneAllIcon />,
        roles: 240,
      },
      {
        path: '/expedition-report',
        label: 'Relatório Expedição',
        icon: <DoneAllIcon />,
        roles: 247,
      },
      {
        path: '/in-out-new',
        label: 'Relatório IN OUT',
        icon: <DoneAllIcon />,
        roles: 239,
      },
      {
        path: '/in-out-expedition',
        label: 'Relatório IN OUT (Expedição)',
        icon: <DoneAllIcon />,
        roles: 248,
      },
    ],
  },
  {
    id: 'reports-releases',
    label: 'Relatórios Lançamentos',
    icon: <SummarizeIcon />,
    roles: 27,
    items: [
      {
        path: '/freeze-approval',
        label: 'Relatório Congelamentos Aprovação ',
        icon: <DoneAllIcon />,
        roles: 232,
      },
    ],
  },
  {
    id: 'reports-receipts',
    label: 'Relatórios Recebimentos',
    icon: <SummarizeIcon />,
    roles: 28,
    items: [
      {
        path: '/inventory-receipt',
        label: 'Relatório Inventário de Recebimento',
        icon: <DoneAllIcon />,
        roles: 252,
      },
      {
        path: '/nf-of-input',
        label: 'Relatório NF de Entrada',
        icon: <DoneAllIcon />,
        roles: 230,
      },
      {
        path: '/report-receipt',
        label: 'Relatório Recebimento',
        icon: <DoneAllIcon />,
        roles: 202,
      },
      {
        path: '/aging-doca',
        label: 'Relatório Aging DOCA',
        icon: <DoneAllIcon />,
        roles: 224,
      },
      {
        path: '/status-log-nf',
        label: 'Status LOG NF',
        icon: <DoneAllIcon />,
        roles: 216,
      },
    ],
  },
];
