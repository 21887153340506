import { api } from 'services/api';

export class ProvidersService {
  async createETicket(data) {
    return await api.post('/providers/correios/solicitarPostagemReversa', data);
  }

  async listEtickets() {
    return await api.get('/providers/e-ticket');
  }

  async calcFare(
    sCepOrigem,
    sCepDestino,
    nVlPeso,
    nCdFormato,
    nVlComprimento,
    nVlAltura,
    nVlLargura,
    nVlDiametro,
  ) {
    return await api.post('/providers/correios/calc-frete', {
      sCepOrigem,
      sCepDestino,
      nVlPeso,
      nCdFormato,
      nVlComprimento,
      nVlAltura,
      nVlLargura,
      nVlDiametro,
    });
  }

  //REPORTS
  async getIngenicoInvoicingReport(input_date, output_date) {
    const { data } = await api.get(
      `/xpcell-ingenico/reports/invoicing/${input_date}/${output_date}`,
    );

    return data;
  }

  async getProductionDepartmentReport(inputDate, outputDate, type, etapa) {
    return api.post(`/reports/production-department`, {
      inputDate: inputDate,
      outputDate: outputDate,
      type: type,
      etapa: etapa,
    });
  }

  async getAtribuido(data) {
    return api.post(`/reports/production-department-atribuido`, data);
  }

  async getExpeditionServiceOrderReport(inputDate, outputDate, type) {
    return api.get(`/xpcell-ingenico/reports/os-expedition/${inputDate}/${outputDate}/${type}`);
  }
  async getReceiptReport(inputDate, outputDate, typeData, typeReceipt) {
    return api.get(
      `/xpcell-ingenico/reports/receipt-report/${inputDate}/${outputDate}/${typeData}/${typeReceipt}`,
    );
  }
  async getTechnicalstock() {
    return api.get(`/xpcell-ingenico/reports/technical-stock`);
  }
  async getOpenOsReport(inputDate, outputDate, Type) {
    return api.get(`/xpcell-ingenico/reports/open-os-report/${inputDate}/${outputDate}/${Type}`);
  }
  async getCarStockReport() {
    return api.get(`/xpcell-ingenico/reports/car-stock-report`);
  }
  async getStockReport() {
    return api.get(`/xpcell-ingenico/reports/stock-report`);
  }
  async getBipingConferenceReport(data) {
    return api.post(`/xpcell-ingenico/reports/biping-conference-report`, data);
  }
  async getLogMovByLocationReport(data) {
    return api.post(`/xpcell-ingenico/reports/log-mov-by-location`, data);
  }
  async getInOutReport(data) {
    return api.post(`/xpcell-ingenico/reports/in-out/`, data);
  }
  async getSerialByPartnumber(serial_data) {
    return api.post(`/xpcell-ingenico/reports/serial-partnumber`, { serial: serial_data });
  }
  async getFreezingShortReport(input_date, output_date) {
    const { data } = await api.get(
      `/xpcell-ingenico/reports/freezing/short/${input_date}/${output_date}`,
    );

    return data;
  }
  async getFreezingDetailedReport(input_date, output_date) {
    const { data } = await api.get(
      `/xpcell-ingenico/reports/freezing/detailed/${input_date}/${output_date}`,
    );

    return data;
  }
  async getAgingReport() {
    return api.get(`/xpcell-ingenico/reports/aging-report`);
  }
  async getAgingFreezingReport() {
    return api.get(`/xpcell-ingenico/reports/aging-freezing-report`);
  }
  async agingWithParts() {
    return api.post(`/xpcell-ingenico/reports/aging-with-parts`);
  }
  async PartsXModelBorReport(data) {
    return api.post(`/xpcell-ingenico/reports/parts-x-model-bor-report`, data);
  }
  async getInventaryExpedition(data) {
    return api.post(`/xpcell-ingenico/reports/inventary-expedition`, data);
  }
  async BorComponent(data) {
    return api.post(`/xpcell-ingenico/reports/component-bor`, data);
  }
  async ManualMovimentationReport(data) {
    return api.post(`/xpcell-ingenico/reports/manual-movimentation`, data);
  }
  async getLogNfTable(data) {
    return api.post(`/xpcell-ingenico/reports/get-status-log-nf`, data);
  }
  async getPartsConsumptionReport(data) {
    console.log(data);
    return api.post(`/xpcell-ingenico/reports/parts-consumption`, data);
  }
  async getAgingDoca() {
    return api.post(`/xpcell-ingenico/reports/getAgingDoca`);
  }

  async getCancellationChargebacks(values) {
    const { data } = await api.post(`/xpcell-ingenico/reports/getCancellationChargebacks`, values);
    return data;
  }

  async getSerialBaseQuery(data) {
    return api.post(`/xpcell-ingenico/reports/getSerialBaseQuery`, data);
  }

  async getBlacklistQuery(data) {
    return api.post(`/xpcell-ingenico/reports/getBlacklistQuery`, data);
  }

  async getClosingNF1(data) {
    return api.post(`/xpcell-ingenico/reports/getClosingNF1`, data);
  }

  async getLaunchParts(data) {
    return api.post(`/xpcell-ingenico/reports/getLaunchParts`, data);
  }

  async geNFinput(data) {
    return api.post(`/xpcell-ingenico/reports/geNFinput`, data);
  }

  async geViewLaudo(data) {
    return api.post(`/xpcell-ingenico/reports/geViewLaudo`, data);
  }

  async getFreezeApproval() {
    return api.post(`/xpcell-ingenico/reports/getFreezeApproval`);
  }

  async getCosmeticScreeningReport(data) {
    return api.post(`/xpcell-ingenico/reports/getCosmeticScreeningReport`, data);
  }

  async getExpressRepair(data) {
    return api.post(`/xpcell-ingenico/reports/getExpressRepair`, data);
  }

  async getQuantityLaudoDay(data) {
    return api.post(`/xpcell-ingenico/reports/getQuantityLaudoDay`, data);
  }

  async getProductionCleaning(data) {
    return api.post(`/xpcell-ingenico/reports/getProductionCleaning`, data);
  }

  async getValueTechnicalStock() {
    return api.post(`/xpcell-ingenico/reports/getValueTechnicalStock`);
  }

  async getAutoCheckCQ(data) {
    return api.post(`/xpcell-ingenico/reports/getAutoCheckCQ`, data);
  }

  async getInOutNew(data) {
    return api.post(`/xpcell-ingenico/reports/getInOutNew`, data);
  }

  async getPackagingReport(data) {
    return api.post(`/xpcell-ingenico/reports/getPackagingReport`, data);
  }

  async getDevolReport(data) {
    return api.post(`/xpcell-ingenico/reports/getDevolReport`, data);
  }

  async getListPN() {
    return api.post(`/xpcell-ingenico/reports/getListPN`);
  }

  async getBorByPn(data) {
    return api.post(`/xpcell-ingenico/reports/getBorByPn`, data);
  }

  async getBorWithoutPn(data) {
    return api.post(`/xpcell-ingenico/reports/getBorWithoutPn`, data);
  }

  async getMinimumStock() {
    return api.post(`/xpcell-ingenico/reports/getMinimumStock`);
  }

  async getRequestsReport(data) {
    return api.post(`/xpcell-ingenico/reports/getRequestsReport`, data);
  }

  async getWfpWithPn(data) {
    return api.post(`/xpcell-ingenico/reports/getWfpWithPn`, data);
  }

  async getExpeditionReport(data) {
    return api.post(`/xpcell-ingenico/reports/getExpeditionReport`, data);
  }

  async getListClient() {
    return api.post(`/xpcell-ingenico/reports/getListClient`);
  }

  async getInOutExpedition(data) {
    return api.post(`/xpcell-ingenico/reports/getInOutExpedition`, data);
  }

  async validateInfoRecuseReport(data) {
    return api.post(`/xpcell-ingenico/reports/validateInfoRecuseReport`, data);
  }

  async whereUsedReport() {
    return api.get(`/xpcell-ingenico/reports/whereUsedReport`);
  }

  async getInventoryReceipt(data) {
    return api.post(`/xpcell-ingenico/reports/getInventoryReceipt`, data);
  }

  //HELPERS
  async getModelosMulti() {
    return api.get(`/xpcell-ingenico/helpers/getModelosMulti`);
  }

  async getFreezingDetailed(input_ordemService) {
    const { data } = await api.post(
      `/xpcell-ingenico/reports/freezing/base-detailed`,
      input_ordemService,
    );

    return data;
  }

  async getConferenceProduction(object) {
    const { data } = await api.post(`/xpcell-ingenico/reports/conference-production`, object);
    return data;
  }

  async getOSPartReleaseControlReport(object) {
    const { data } = await api.post(
      `/xpcell-ingenico/reports/os-part-release-control-report`,
      object,
    );
    return data;
  }

  async getDoaBounceReport(object) {
    const { data } = await api.post(`/xpcell-ingenico/reports/doaBounce-Report`, object);
    return data;
  }

  async getIngenicoReportFreezingPending(object) {
    const { data } = await api.post(`/xpcell-ingenico/reports/report-freezing-pendin`, object);
    return data;
  }

  async removeBlackListGaia(object) {
    const { data } = await api.post(`/xpcell-ingenico/reports/remove-black-list`, object);
    return data;
  }

  async getInfoOsStatus(object) {
    const { data } = await api.post(`/xpcell-ingenico/reports/status-os`, object);
    return data;
  }

  async getAllInfo(object) {
    const { data } = await api.post(`/xpcell-ingenico/reports/getAllInfo`, object);
    return data;
  }

  async reportGenerator(object) {
    return await api.post(`/xpcell-ingenico/reports/reportGenerator`, object, {
      responseType: 'blob',
    });
  }

  // INCONTROL

  async getIncontrolReport(input_data, output_data) {
    return api.post(`/providers/incontrol`, {
      input_data: input_data,
      output_data: output_data,
    });
  }

  async getEtapas() {
    const { data } = await api.get(`/reports/production-department/etapa`);
    return data;
  }
}

export const providerService = new ProvidersService();
